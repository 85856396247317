(function () {
    if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", initialize);
    } else {
        initialize();
    }

    function initialize() {
        document
            .querySelectorAll(".dynamic-content")
            .forEach(function (element) {
                const dataContent = element.getAttribute("data-content");
                let contentData = null;
                if (dataContent) {
                    contentData = JSON.parse(dataContent);
                }

                fetchCardDetails(contentData, element);
            });
    }

    function fetchCardDetails(contentData, element) {
        const affiliateLinkId = contentData.custom_affiliate_link_id;
        const cardId = contentData.credit_card_id;
        const affiliateId = contentData.affiliate_id

        const url = affiliateLinkId
            ? `${contentData.app_url}/cards/get-card-details-by-affiliate/${affiliateLinkId}?card_id=${cardId}&affiliate_id=${affiliateId}`
            : `${contentData.app_url}/cards/get-card-details/${cardId}?affiliate_id=${affiliateId}`;

        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);

        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    populateCardDetails(element, contentData, response);
                }
            }
        };
        xhr.send();
    }
    function populateCardDetails(element, contentData, response) {
        const selectedCard = response.embedCard;
        const originalCard = response.originalCard;

        if (originalCard.embed_show === false) {
            $("#htmlRawCode").hide();
        } else {
            var cssLink = document.createElement("link");
            cssLink.rel = "stylesheet";
            cssLink.href = `${contentData.app_url}/css/embed_card.css`;
            document.head.appendChild(cssLink);
        }
        const cardImgSrc = `https://mvaffiliatecdn.nyc3.cdn.digitaloceanspaces.com/shared/images/cards/${originalCard.img_name}`;
        const shortDesc = originalCard.short_desc;
        const introBonus =
            selectedCard.new_intro_bonus_description ??
            originalCard.intro_bonus_description;
        const introBonusDisplayText =
            selectedCard.new_intro_bonus_display_text ??
            originalCard.intro_bonus_display_text;
        const introBonusSpendAmount =
            selectedCard.new_intro_bonus_spend_amount ??
            originalCard.intro_bonus_spend_amount;
        const introBonusSpendPeriod =
            selectedCard.new_intro_bonus_spend_period_value ??
            originalCard.intro_bonus_spend_period_value;
        const introBonusSpendUnit =
            selectedCard.new_intro_bonus_spend_period_unit ??
            originalCard.intro_bonus_spend_period_unit;
        const introBonusDisplayTextAddendum =
            selectedCard.new_intro_bonus_display_text_addendum ??
            originalCard.intro_bonus_display_text_addendum;
        let introBonusSr = "";
        let affiliateLink = "";

        if (
            introBonusSpendAmount &&
            introBonusSpendPeriod &&
            introBonusSpendUnit
        ) {
            introBonusSr =
                "After you spend $" +
                numberWithCommas(introBonusSpendAmount) +
                " on purchases within the first " +
                introBonusSpendPeriod +
                " " +
                introBonusSpendUnit +
                " of account opening." + (introBonusDisplayTextAddendum ? " " + introBonusDisplayTextAddendum : '');
        }

        affiliateLink = response.customAffiliateLink
            ? response.customAffiliateLink.custom_affiliate_utm_link ||
            response.customAffiliateLink.custom_affiliate_link
            : originalCard.affiliate_url + '?aff=' + encodeURIComponent(response.affiliateLinkId);

        element.innerHTML = "";

        const cardContainer = document.createElement("div");
        cardContainer.classList.add("card-container");
        cardContainer.style.marginBottom = "20px";

        function createContentBlock(innerHTML, textContent) {
            const contentBlock = document.createElement("div");
            contentBlock.style.marginBottom = "20px";
            if (innerHTML) {
                contentBlock.innerHTML = innerHTML;
            }
            if (textContent) {
                contentBlock.textContent = textContent;
            }
            cardContainer.appendChild(contentBlock);
        }

        switch (contentData.field) {
            case "promotion_block":
                createContentBlock(response.promotionBlockContent);
                break;
            case "art_with_link":
                checkImageExists(
                    cardImgSrc,
                    () => {
                        // Image exists, create the content block with the image
                        createContentBlock(
                            `<a href="${affiliateLink}" target="_blank" id="art-with-link-only"><img class="w-[191px] h-[120px] max-w-full h-auto shadow mb-3" src="${cardImgSrc}" alt="Card Image"></a>`
                        );
                    },
                    () => {
                        // Image does not exist, create the content block with a placeholder
                        createContentBlock(
                            `<div class="w-[191px] h-[120px] max-w-full shadow mb-3 bg-gray-200 flex items-center justify-center">
                                <span class="text-gray-700 text-sm text-center">Image Not Available</span>
                            </div>`
                        );
                    }
                );
                break;
            case "name_only":
                createContentBlock(null, shortDesc);
                break;
            case "name_with_link":
                createContentBlock(
                    `<a href="${affiliateLink}" target="_blank" id="name-with-link-only">${shortDesc}</a>`
                );
                break;
            case "intro_offer_full":
                createContentBlock(null, introBonus);
                break;
            case "intro_offer_sr":
                createContentBlock(null, introBonusSr);
                break;
            case "intro_offer_bonus_amount":
                createContentBlock(null, introBonusDisplayText);
                break;
        }

        element.appendChild(cardContainer);
    }

    function setLinkClickable(linkId, url) {
        const link = document.querySelector(linkId);
        if (url) {
            link.href = url;
            link.style.pointerEvents = "auto";
        } else {
            link.removeAttribute("href");
            link.style.pointerEvents = "none";
            link.addEventListener("click", function (event) {
                event.preventDefault();
            });
        }
    }

    function numberWithCommas(number) {
        return number
            ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "";
    }

    function checkImageExists(src, onSuccess, onError) {
        const img = new Image();
        img.onload = onSuccess;
        img.onerror = onError;
        img.src = src;
    }

})();
